<div class="header">
  <div class="headerContent">
    <div class="surname">Katarzyna Knapik</div>
    <ul class="menu">
      <li class="home"><a i18n (click)="showMainPage()">Home</a></li>
      <li class="experience"><a [ngClass]="weActive ? 'active' : ''" i18n (click)="goTo('experience')">Work experience</a></li>
      <li class="education"><a [ngClass]="eduActive ? 'active' : ''" i18n (click)="goTo('education')">Education</a></li>
      <li class="skills"><a [ngClass]="skillsActive ? 'active' : ''" i18n (click)="goTo('skills')">Skills</a></li>
    </ul>
    <ul class="contactIcons">
      <li>
        <a [href]="linkedInURL"
            class="linkedIn"
            target="_blank"></a>
      </li>
      <li>
        <a href="mailto:{{email}}"
            class="email"></a>
      </li>
      <li>
        <a [href]="gitlabURL"
            class="gitlab"
            target="_blank"></a>
      </li>
    </ul>
  </div>
  <div class="spacer"></div>
</div>
