import { Component, OnInit } from '@angular/core';
import { EMAIL, GITLAB, LINKEDIN } from 'src/app/const/contact.const';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent implements OnInit {
	public email: string;
	public linkedIn: string;
	public linkedInURL: string;
	public gitlab: string;
	public gitlabURL: string;


	constructor() {}

	/**
	 * On init function
	 */
	ngOnInit() {
		this.email = EMAIL;
		this.linkedIn = LINKEDIN;
		this.linkedInURL = 'https://www.' + LINKEDIN;
		this.gitlab = GITLAB;
		this.gitlabURL = 'https://' + GITLAB;
	}
}
