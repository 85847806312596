export interface SkillInterface {
	skillName: string;
	skillLevel: number;
	skillOrder: number;
	skillWIdth?: string;
}

export class Skill {

	static readonly TECHNICAL_SKILLS: SkillInterface[] = [
		{
			skillName: 'Angular 4+',
			skillLevel: 4,
			skillOrder: 1
		},
		{
			skillName: 'React',
			skillLevel: 2.5,
			skillOrder: 2
		},
		{
			skillName: 'TypeScript',
			skillLevel: 3,
			skillOrder: 3
		},
		{
			skillName: 'Vue',
			skillLevel: 3.5,
			skillOrder: 4
		},
		{
			skillName: 'SCSS',
			skillLevel: 4.5,
			skillOrder: 5
		}
	];

	static readonly PERSONAL_SKILLS: SkillInterface[] = [
		{
			skillName: 'Practical',
			skillLevel: 5,
			skillOrder: 1
		},
		{
			skillName: 'Punctual',
			skillLevel: 4,
			skillOrder: 2
		},
		{
			skillName: 'Teamwork',
			skillLevel: 4.5,
			skillOrder: 3
		},
		{
			skillName: 'Clean code',
			skillLevel: 5,
			skillOrder: 4
		},
		{
			skillName: 'Leadership',
			skillLevel: 3.5,
			skillOrder: 5
		}
	];
}
