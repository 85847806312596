import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-main-info',
	templateUrl: './main-info.component.html',
	styleUrls: ['./main-info.component.scss']
})
export class MainInfoComponent implements OnInit {
	public skills: string[];
	@Output() showResumeClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>()

	constructor() { }

	/**
	 * Show resume
	 */
	showResume() {
		this.showResumeClickedEvent.emit(false)
	}

	//   showPortfolio() {
	//     this.showResumeClickedEvent.emit(false)
	//   }

	/**
	 * On init function
	 */
	ngOnInit() {
		this.skills = ['HTML', 'SCSS', 'JavaScript', 'Angular', 'React', 'Vue'];
	}

}
