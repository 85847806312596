<div class="item skills">
    <div class="itemContent">
        <h2 i18n>Skills</h2>
        <div class="content">
            <div class="skillItems left">
                <h3 i18n>Technical skills</h3>
                <div *ngFor="let skill of technicalSkills"
                    class="skill">
                    <div class="skillName">
                        {{skill.skillName}}
                    </div>
                    <div class="skillLevel">
                        <div #test class="skillItem" [style.width]="skill.skillWIdth"></div>
                    </div>
                </div>
            </div>
            <div class="skillItems right">
                <h3>Personal skills</h3>
                <div *ngFor="let skill of personalSkills"
                    class="skill">
                    <div class="skillName">
                        {{skill.skillName}}
                    </div>
                    <div class="skillLevel">
                        <div class="skillItem" [style.width]="skill.skillWIdth"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="group"></div>
    </div>
</div>