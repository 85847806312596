<div class="item education">
    <div class="itemContent">
        <h2 i18n>Education</h2>
        <div class="content">
            <div i18n class="dates">October 2008 -<br>June 2012</div>
            <div class="description">
                <div i18n class="item_01">Informatics</div>
                <div i18n class="item_02">Warsaw University of Technology</div>
                <div i18n class="item_03">Bachelor of Science in Engineering</div>
                <div i18n class="item_04"><span>Speciality:</span> Information technology graduated with the highest remark.</div>
            </div>
        </div>
    </div>
</div>