<div class="item experience">
    <div class="itemContent">
        <h2 i18n>Work experience</h2>
        <div class="content">
            <div i18n class="dates">
                November 2017 -<br>
                <span class="length">(4y 1m and counting)</span>
            </div>
            <div class="description">
                <ul class="icons">
                    <li class="angular">
                        <span>Angular</span>
                    </li>
                    <li class="vue">
                        <span>Vue</span>
                    </li>
                    <li class="git">
                        <span>Git Hub</span>
                    </li>
                    <li class="jira">
                        <span>Jira</span>
                    </li>
                </ul>
                <div class="item_01">
                    <span class="bg"></span>
                    <span i18n class="position">Software Engineer</span>
                </div>
                <div i18n class="item_02">Samsung Research</div>
                <div i18n class="item_03">Responsibilities</div>
                <ul class="list_01">
                    <li i18n>- Implementation of education system according to technical documentation</li>
                    <li i18n>- Adjusting application UI based on graphics projects</li>
                </ul>
                <div i18n class="item_03">Technologies and utils</div>
                <ul class="list_01 utils">
                    <li class="print">
                        <span>Frameworks</span> Angular, AngularJS, React, Vue
                    </li>
                    <li>
                        <span>Design systems</span> Material UI, Vuetify
                    </li>
                    <li>
                        <span>Working flow</span> Jira, Webstorm, Git / Git flow
                    </li>
                    <li>
                        <span>Other</span> Type Script, SCSS, ngRx, Store, Rest API
                    </li>
                </ul>
            </div>
        </div>
        <div class="content">
            <div i18n class="dates">
                October 2007 -<br>October 2017<br>
                <span class="length">(10 years)</span>
            </div>
            <div class="description">
                <ul class="icons">
                    <li class="react">
                        <span>React</span>
                    </li>
                    <li class="javascript">
                        <span>Java Script</span>
                    </li>
                    <li class="jquery">
                        <span>jQuery</span>
                    </li>
                    <li class="vs">
                        <span>Visual Studio</span>
                    </li>
                </ul>
                <div class="item_01">
                    <span class="bg"></span>
                    <span i18n class="position">Senior Frontend Developer</span>
                </div>
                <div i18n class="item_02">Fabrity K2</div>
                <div i18n class="item_03">Responsibilities</div>
                <ul class="list_01">
                    <li i18n>- Maintenance and development of projects based on MVC.NET, AngularJS and K2 Smartforms technologies</li>
                    <li i18n>- Adjusting application UI based on graphics projects</li>
                    <li i18n>- Implementation of web applications interactivity</li>
                </ul>
                <div i18n class="item_03">Technologies and utils</div>
                <ul class="list_01 utils">
                    <li class="print">
                        <span>Basics</span> html 5, css (less, sass), Java Script
                    </li>
                    <li class="print">
                        <span>Frameworks</span> AngularJS, React, jQuery
                    </li>
                    <li>
                        <span>Design systems</span> Adobe Photoshop, MS Sharepoint Designer
                    </li>
                    <li>
                        <span>Working flow</span> Visual Studio, Team Foundation Server
                    </li>
                    <li>
                        <span>Other</span> K2 Smartforms, K2 Workflows, MS Sharepoint
                    </li>
                </ul>
            </div>
        </div>
        <div class="content">
            <div i18n class="dates">
                January 2006 -<br>September 2007<br>
                <span class="length">(1y 8m)</span>
            </div>
            <div class="description">
                <ul class="icons">
                    <li class="html">
                        <span>Html</span>
                    </li>
                    <li class="css">
                        <span>css</span>
                    </li>
                    <li class="photoshop">
                        <span>Photoshop</span>
                    </li>
                    <li class="eclipse">
                        <span>Eclipse</span>
                    </li>
                </ul>
                <div class="item_01">
                    <span class="bg"></span>
                    <span i18n class="position">Programmer</span>
                </div>
                <div i18n class="item_02">EO Networks</div>
                <div i18n class="item_03">Responsibilities</div>
                <ul class="list_01">
                    <li i18n>- Designing, deployment and maintenance web applications based on CAVA CMS system</li>
                    <li i18n>- Creating technical and user documentation</li>
                    <li i18n>- Leading frontend developers team</li>
                </ul>
                <div i18n class="item_03">Technologies and utils</div>
                <ul class="list_01 utils">
                    <li class="print">
                        <span>Basics</span> (x)html, css, Java Script
                    </li>
                    <li>
                        <span>Design systems</span> Adobe Photoshop
                    </li>
                    <li>
                        <span>Working flow</span> Eclipse, cvs, svn
                    </li>
                    <li>
                        <span>Other</span> xslt, velocity
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>