<div class="footer">
    <div class="spacer"></div>
    <div class="contactData">
        <div i18n class="subTitle">
            Contact
        </div>
        <div class="item print">
            <a [href]="linkedInURL" class="linkedIn" target="_blank">{{linkedIn}}</a>
        </div>
        <div class="item print">
            <a href="mailto:{{email}}" class="email">{{email}}</a>
        </div>
        <div class="item print webHide">
            <a class="phone">+48 881 632 221</a>
        </div>
        <div class="item">
            <a [href]="gitlabURL" class="gitlab" target="_blank">{{gitlab}}</a>
        </div>
    </div>
</div>