import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SkillInterface, Skill } from 'src/app/const/skills.const';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  public technicalSkills: SkillInterface[];
  public personalSkills: SkillInterface[];
  @ViewChild('test') element: ElementRef;

  constructor(private render: Renderer2) {}

  ngOnInit(): void {
    this.technicalSkills = Skill.TECHNICAL_SKILLS;
    this.personalSkills = Skill.PERSONAL_SKILLS;
    this.technicalSkills.forEach(skill => {
      skill.skillWIdth = ((skill.skillLevel * 340) / 5) + 'px';
    })
    this.personalSkills.forEach(skill => {
      skill.skillWIdth = ((skill.skillLevel * 340) / 5) + 'px';
    })
    this.render.listen('window', 'load', () => {
      console.log(this.element)
      const rect = this.element.nativeElement.getBoundingClientRect();
    })
  
  }

}
