import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EMAIL, GITLAB, LINKEDIN } from 'src/app/const/contact.const';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: [ './menu.component.scss' ]
})
export class MenuComponent implements OnInit {
	public description: string;
	@Output() showMainPageClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
	@Output() navigateToElementClickedEvent: EventEmitter<string> = new EventEmitter<string>()
	public email: string;
	public linkedInURL: string;
	public gitlabURL: string;
	public weActive: boolean;
	public eduActive: boolean;
	public skillsActive: boolean;

	constructor() {
	}

	turnOffActive(): void {
		this.weActive = false;
		this.eduActive = false;
		this.skillsActive = false;
	}

	goTo(id: string) {
		this.turnOffActive();
		this.navigateToElementClickedEvent.emit(id)
		switch(id) {
			case 'experience':
				this.weActive = true;
				break;
			case 'education':
				this.eduActive = true;
				break;
			case 'skills':
				this.skillsActive = true;
				break;
		}
	}

	showMainPage() {
		this.turnOffActive();
		this.showMainPageClickedEvent.emit(true)
	}

	/**
	 * On init function
	 */
	ngOnInit() {
		this.email = EMAIL;
		this.linkedInURL = 'https://www.' + LINKEDIN;
		this.gitlabURL = 'https://' + GITLAB;
	}
}
