import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  @Input() goToElementIdObservable: Observable<string>

  constructor() { }

  scrollToElement(id: string): void {
    const element = document.querySelector("#" + id)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  print(): void {
    window.print();
  }

  ngOnInit(): void {
    this.goToElementIdObservable.subscribe((value) => {
      if (value) {
          this.scrollToElement(value)
      }
    });
  }

}
