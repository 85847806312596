<div class="mainInfo">
  <div class="name">
    Katarzyna Knapik
  </div>
  <div class="position">
    Frontend Developer
  </div>
  <div i18n class="description">
    Over 15 years experience
  </div>
  <div i18n class="description">
    B2 Certificated English Speaker
  </div>
  <div class="skills">
    <div *ngFor="let skill of skills">
      {{ skill }}
    </div>
  </div>
  <div i18n class="button"
      (click)="showResume()">
    Resume
  </div>
  <!-- <div i18n class="button"
       (click)="showPortfolio()">
    Portfolio
  </div> -->
  <span class="codeLines"></span>
</div>
