import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
	public contentClass: string;
	public sideNavOpened: boolean;
	public fullPage: boolean;
	public goToElementId: Subject<string> = new Subject<string>()

	showResumedClicked() {
		this.contentClass = 'show-resume-progress';
		setTimeout(() => {
			this.contentClass = ' show-resume-finished';
		}, 350)
	}

	showHomePage() {
		this.contentClass = 'show-hp-progress';
		setTimeout(() => {
			this.contentClass = 'show-hp-finished';
		}, 350)
	}

	goToElement(event: any) {
		this.goToElementId.next(event)
	}

	/**
	 * Print CV
	 */
	printCv(){
		window.print();
	}

  /**
	 * On init function
	 */
	ngOnInit() {
		this.fullPage = false;
	}

}
